import Controller from "./application_controller";

export default class ProgramPreviewController extends Controller {
  static targets = ["tooltip"];

  connect() {
    super.connect();
  }

  contact(event) {
    event.preventDefault();
    this.tooltipTarget.classList.toggle("invisible");
  }
}
