import Controller from "./application_controller";
import { Cloudinary } from "cloudinary-core";
import VideoPlayer from "cloudinary-video-player";

export default class VideoController extends Controller {
  static targets = ["videoPlayer"];

  connect() {
    super.connect();
    const id = this.data.element.dataset.id;
    const cloud = new Cloudinary({ cloud_name: process.env.CLOUDINARY_CLOUD_NAME, secure: true });
    this.videoPlayerTargets.forEach((target) => {
      const transformation = [{
        streaming_profile: '4k',
      }];

      const player = cloud.videoPlayer(`lesson-video-player-${id}`, {
        controls: true,
        floatingWhenNotVisible: 'right',
        fluid: true,
        hideContextMenu: false,
        playedEventPercents: [1, 25, 50, 75, 90, 100],
        playedEventTimes: [...Array(90).keys()],
        preload: 'auto',
        seekThumbnails: true,
        showJumpControls: true,
        showLogo: false,
        sourceTypes: ['hls'],
        transformation,
      });

      const position = target.dataset.position;
      if (position) player.currentTime(position);

      if (target.dataset.tracking === 'false') return;

      player.on('percentsplayed', ({ eventData }) => {
        if (eventData.percent === 1) this.stimulate('LessonProgress#start')
      });

      player.on('timeplayed', ({ eventData }) => {
        this.stimulate('LessonProgress#update', eventData.time);
      });

      player.on('percentsplayed', ({ eventData }) => {
        this.stimulate('LessonProgress#update_percent', eventData.percent);
      });
    });
  }
}
