// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.

import StimulusReflex from "stimulus_reflex"
import { Application } from "stimulus"
import { Modal } from "tailwindcss-stimulus-components"
import { definitionsFromContext } from "stimulus/webpack-helpers"
import consumer from "../channels/consumer"
import controller from "./application_controller"

const application = Application.start()
const context = require.context("controllers", true, /_controller\.js$/)
application.load(definitionsFromContext(context))
application.register("modal", Modal)

StimulusReflex.initialize(application, { consumer, controller, debug: false })
